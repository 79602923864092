/* Navbar.module.css */

/* Estilos comunes para el Navbar */
.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative; /* Añade posición relativa para apilar elementos */
}

.logo {
  max-width: 30%;
  height: auto; /* Hace que la imagen del logo sea responsive */
  margin-left: 60px;
}

.menuButton {
  display: none; /* Oculta el botón del menú en pantallas grandes */
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: rgb(7, 7, 7);
  cursor: pointer;
}

/* Estilos para el menú desplegable en pantallas pequeñas */
@media (max-width: 767px) {
  .menuButton {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .navlinksContainer {
    list-style: none;
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background-color: #f8e1b6;
    font-weight: bold;
    transition: all 0.3s ease-in;
    z-index: 999; /* Ajusta el z-index para que el menú aparezca arriba de todo */
  }

  .navlinksContainer.active {
    display: flex;
  }

  /* Estilos para los enlaces en el menú en pantallas pequeñas */
  .navlinksContainer li {
    margin: 10px 0;
  }

  .navlinksContainer a {
    text-decoration: none;
    color: rgb(10, 10, 10);
  }
}

/* Estilos para los enlaces en el menú en pantallas grandes */
@media (min-width: 768px) {
  .navlinksContainer {
    list-style: none;
    display: flex; /* Cambia la dirección de los elementos a horizontal */
    align-items: center;
    font-weight: bold;
  }

  .navlinksContainer li {
    margin: 0 10px;
  }

  .navlinksContainer a {
    text-decoration: none;
    color: rgb(10, 10, 10);
    transition: color 0.3s ease-in-out; /* Agrega un efecto de transición para el color de los enlaces */
  }

  .navlinksContainer a:hover {
    color: #fac664; /* Cambia el color al hacer hover en los enlaces */
  }
}




.ReproContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000; 
}