
.imagen-con-texto {
  position: relative;
  overflow: hidden; 
}

.imagen-con-texto img {
  width: 100%;
  height: 30rem;
  max-height: 100%;
}

.texto-sobre-imagen {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: rgb(15, 15, 15); 
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px; 
  max-width: 80%; 
  border-radius: 10px;
}

.texto-sobre-imagen h2 {
  font-size: 2.5rem; 
  margin-bottom: 10px; 
}

.texto-sobre-imagen p {
  font-size: 1.25rem; 
  font-weight: bold;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .texto-sobre-imagen {
    max-width: 90%; 
    padding: 15px; 
  }

  .texto-sobre-imagen h2 {
    font-size: 2rem; 
  }

.texto-sobre-imagen p {
    font-size: 1rem; 
    font-weight: normal; 
  }
  
  }


