
.bg-primary {
  background-color: #e4e2e2;
}

.titulo{
font-weight: bold;
font-size: 25px;
}
.seccion{
  background-color: rgb(253, 253, 250);
}
.icono{
  color: #fcc46f;
}

.seccion {
  height: 300px; 
}
.text-xl{
  color: #4b4a4a;
  font-weight: bold;
}
.text-base{
color: #686868;
font-weight: bold;
}
