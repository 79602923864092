.superpuestoFrase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 3.5rem;
  z-index: 1; 
}
