
.reproductor {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #fdc775;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.5rem;
}

.reproductor:hover {
  background-color:  #fdc775;
}

.img1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #fdc775;
  color: #fff;
  font-size: 1;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px;

}


.img2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdc775;
  color: #fff;
  font-size: 1;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px;

}

