.prefrecuentes {
  margin: 50px auto;
  max-width: 50%px;
 
}


.preguntasfrecuentesh2 {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.preguntas {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pregunta {
  flex: 1;
  margin: 0 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  background-color: #f8c880d0;
  transition: box-shadow 0.3s ease;
}

.pregunta:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.pregunta h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.pregunta p {
  font-size: 16px;
  line-height: 1.5;
  margin: 10px 0 0;
}

@media only screen and (max-width: 480px) {
  .preguntas {
    flex-wrap: wrap;
    justify-content: center;
  }
  .pregunta {
    margin: 20px;
    max-width: 300px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .pregunta {
    max-width: 400px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .pregunta {
    max-width: 600px;
  }
}
