/* Footer.module.css */
.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centra verticalmente */
  flex-wrap: wrap;
  gap: 20px; /* Espacio entre las dos columnas */
  margin: 20px auto; /* Margen exterior */
  max-width: 900px; /* Ancho máximo del footer */
 
}

.column {
  flex: 1;
  text-align: center;
  padding: 20px; /* Margen interno */
  background-color: #f8c880d0; /* Color de fondo de las columnas */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
  margin-top: 30px;
}

.column h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  margin: auto;
  
}

.socialcontainer {
  display: flex;
  align-items: center; /* Centra horizontalmente */
  margin-top: 10px; /* Espacio entre el título y los íconos sociales */
  justify-content: center;
}

.socialw {
  font-size: 2rem;
  margin: 0 10px; /* Espacio entre los íconos sociales */
  color: #333;
  transition: color 0.3s ease; /* Transición de color al pasar el ratón */
  
}

.sociali {
  font-size: 2rem;
  margin: 0 10px; /* Espacio entre los íconos sociales */
  color: #333;
  transition: color 0.3s ease; /* Transición de color al pasar el ratón */
 
}

.sociali:hover {
  color: #ff6600; /* Color al pasar el ratón */
}
.socialw:hover {
  color: #ff6600; /* Color al pasar el ratón */
}

.copyRight {
  text-align: center;
  margin-top: 20px;
  padding: 10px; /* Margen interno para el aviso de derechos reservados */
  background-color: #f0f0f0; /* Color de fondo del aviso */
  border-radius: 10px; /* Bordes redondeados */
  font-size: 12px;
}

.footer{
  background-color: #f0f0f0; 
}

