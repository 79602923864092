.lineadecorativa {
    height: 3vh;
    background-image: url('../../Imagenes/linea.jpg');
    background-repeat: no-repeat ;
    background-position: center;
    margin: 50px 0em 30px auto
 
  }


  
  
