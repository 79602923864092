.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    background-image: url("../../Imagenes//estrellasoro.jpg");
  }
  
  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    gap: 50px;
    text-align: center; 
 
  }
  
  
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    
  }
  
  h2 {
    font-style: italic;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
 
  
  .box {
    flex: 1; 
    margin: 30px;
    border: 10px solid  #b9b9b9;
    padding: 20px;
    font-weight: bold;
    background-color: #f7f7f7;
  }

 
  
  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-style: italic;
    font-size: 15px;

    
  }
  .listp {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-style: italic;
    font-size: 15px;
  color: rgb(58, 58, 58);
    
  }

  .pp {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-style: italic;
    font-size: 13px;
  color: rgb(58, 58, 58);
    
  }
  
  .list li {
    margin-bottom: 10px;
  }
  

  

  @media screen and (max-width: 320px) {
   
    .column {
      width: 100%;
    }
    .row {
      flex-direction: column;
    }
  }
  
  @media screen and (min-width: 321px) and (max-width: 480px) {
  
    .column {
      width: 100%;
    }
    .row {
      flex-direction: column;
    }
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
  
    .column {
      width: 100%;
    }
    .row {
      flex-direction: column;
    }
  }
  
  @media screen and (min-width: 769px) {
   
    .column {
      width: 50%;
    }
    .row {
      flex-direction: column;
    }
  }