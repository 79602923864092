.nosotros {
    background-color: #f6f6f6;
    padding: 50px 0;
  }
  
  .nosotros h2 {
    font-style: italic;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .nosotros p {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .servicios {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

  }
  
  .servicio {
    width: 20%;
    margin-bottom: 50px;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  
  .servicio:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
  }
  
  .servicio img {
    width: 100%;
    height: auto;
  }

  .servicio h3 {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 50px 0 0 auto;
  }

  .servicio p {
    position: absolute;
    top: -35px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 100px 0 0 1px;
    
  }
  .videoModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 2rem;
  }
  
  .videoModal video {
    max-width: 100%;
    max-height: 70vh;
  }
  
  .videoModal button {
    margin-top: 1rem;
    color: #f6f6f6;
  }
  
  @media screen and (max-width: 768px) {
    .nosotros {
      padding: 50px 0;
    }
    
    .nosotros h2 {
      margin-bottom: 30px;
    }
    
    .nosotros p {
      margin-bottom: 5px;
    }
    
    .servicio {
      margin-bottom: 50px;
      width: 300px;
    }
    
    .servicio:hover {
      transform: translateY(-10px);
    }
    
    .servicio h3 {
      top: -20px;
      margin: 50px 0 0 auto;
    }
  
    .servicio p {
      top: -35px;
      margin: 100px 0 0 1px;
    }
  
    
    .videoModal {
      padding: 6rem;

    }
    .videoModal video {
      max-height: 80vh; /* aumenta el valor para que el video se vea más grande */
      max-width: none; /* elimina el valor para que el ancho del video se ajuste al contenedor */
    }
    
 

    .videoModal button {
      margin-top: 1rem;
    }
  }

 
  
  
  
